body{
    background-color: #252D39;
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray; 
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2EC862;;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #252D39;
    cursor: pointer;
}

.App {
    display: flex;
    height: 100vh;
  }
  
.navigation button {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  text-align: left;
  cursor: pointer;
}
.navigation {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.navigation.horizontal {
  flex-direction: row;
}

.navigation.vertical {
  flex-direction: column;
}
.question-display {
  width: 80%;
  padding: 20px;
}

.question-display h2 {
  margin-bottom: 20px;
}

.question-display ul {
  list-style: none;
  padding: 0;
}

.question-display li {
  margin-bottom: 10px;
}

.App {
  display: flex;
  height: 100vh;
}

.navigation {
width: 20%;
background-color: #f0f0f0;
padding: 10px;
}

.navigation button {
display: block;
width: 100%;
padding: 10px;
margin: 5px 0;
text-align: left;
cursor: pointer;
}

.question-display {
width: 80%;
padding: 20px;
}

.question-display h2 {
margin-bottom: 20px;
}

.question-display ul {
list-style: none;
padding: 0;
}

.question-display li {
margin-bottom: 10px;
}